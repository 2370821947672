import { Component } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

//declare let ga: Function;
declare let gtag:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'homepage';

  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        gtag('config','UA-{ID}-2',{'page_path' : y.url});
        this.loadOpenSeaDragon();
        // console.log("NavigationEnd");
      }
    })
    $("#openseadragon1").addClass("bg-dark");
  }

  onActivate(event: any) {
    // console.log("onActivate");
    this.loadOpenSeaDragon();
  }

  loadOpenSeaDragon() {
    $("#openseadragon1").addClass("bg-dark");
    if (isPlatformBrowser(this.platformId)) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 4);
    }
  }

}
