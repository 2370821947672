<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/DSC_M51.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/DSC_M51_Annotations/'"
                    [imageWidth]="5678"
                    [imageHeight]="4280" [imageScale]="0.4"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'L', brand: '', exposures: [{ count: 1806, total: 248220 }] },
                                        { filter: 'R', brand: '', exposures: [{ count: 597, total: 83925 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 594, total: 88290 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 542, total: 69885 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 1048, total: 427170 }] }]"
                    [nights]="{ count: 75, start: '2022-05-06', end: '2023-07-10' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['Askar FRA600', 'Celestron C9.25 SC XLT', 'Sky-Watcher Esprit 150ED', 'Sky-Watcher Explorer 200PDS', 'Sky-Watcher Explorer 250PDS', 'Sky-Watcher Quattro 200P', 'Sky-Watcher Quattro 250P / 10-S', 'Stellarvue SVX130T', 'Tecnosky RC8 Carbon', 'TPO 8&quot; f/4 Newtonian', 'TS-Optics 250mm/10&quot; ONTC f/4 Newtonian (carbon tube)', 'William Optics Fluorostar 132 / FLT132'],
                                    cameras: ['QHYCCD QHY163M', 'QHYCCD QHY600PH M', 'RisingCam ATR3-26000KMA', 'ZWO ASI1600MM COOL', 'ZWO ASI2600MC Pro', 'ZWO ASI2600MM Pro', 'ZWO ASI294MC Pro', 'ZWO ASI294MM Pro', 'ZWO ASI533MM Pro', 'ZWO ASI6200MM Pro' ] }">
</app-astro-photo>