import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/snr156.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-snr156',
  templateUrl: './snr156.component.html',
  styleUrls: ['./snr156.component.sass']
})
export class Snr156Component {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;
}
