import { Component, OnInit } from '@angular/core';
import jsonBuildDate from '../../../environments/build-date.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  public buildDate: string = jsonBuildDate.builddate;

  constructor() { }

  ngOnInit() {
  }

  currentYear(): number {
    return new Date().getFullYear();
  }

  scrollToTop(): void {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 50); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 4);
  }

}
