import { Component } from '@angular/core';
import jsonTutorials from '../../../locale/data/tutorials.json';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.sass']
})
export class TutorialsComponent {
  tutorials: any = jsonTutorials;

}
