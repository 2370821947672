import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/casa-dsc.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-casa-dsc',
  templateUrl: './casa-dsc.component.html',
  styleUrls: ['./casa-dsc.component.sass']
})
export class CasaDscComponent {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;
}
