import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/m51-dsc.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-m51-dsc',
  templateUrl: './m51-dsc.component.html',
  styleUrls: ['./m51-dsc.component.sass']
})
export class M51DscComponent {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;
}
