<div class="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content bg-secondaryx bg-dark text-secondary">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" i18n="Contact form title@@contact.form.title">Let's get in touch
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal"
          i18n-aria-label="Contact form message@@contact.form.close" aria-label="Close">
        </button>
      </div>
      <div id="formBody" class="modal-body">
        <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
          <div class="form-group">
            <label for="inputName" class="col-form-label" i18n="Contact form name@@contact.form.name">Name</label>
            <input type="text" class="form-control bg-secondary text-light" id="inputName" name="inputName" #inputName="ngModel"
              i18n-aria-label="Contact form message@@contact.form.input.name" aria-label="Your Name" required ngModel [class.is-invalid]="inputName.invalid">
          </div>
          <div class="form-group">
            <label for="inputEmail" class="col-form-label" i18n="Contact form email@@contact.form.email">Email</label>
            <input type="email"
              class="form-control bg-secondary text-light" id="inputEmail" name="inputEmail" #inputEmail="ngModel"
              i18n-aria-label="Contact form message@@contact.form.input.email" aria-label="Your Email" required email ngModel  [class.is-invalid]="inputEmail.invalid">
          </div>
          <div class="form-group">
            <label for="inputSubject" class="col-form-label"
              i18n="Contact form subject@@contact.form.subject">Subject</label>
            <input type="text"
              class="form-control bg-secondary text-light" id="inputSubject" name="inputSubject" #inputSubject="ngModel"
              i18n-aria-label="Contact form message@@contact.form.input.subject" aria-label="Message Subject" required ngModel  [class.is-invalid]="inputSubject.invalid">
          </div>
          <div class="form-group">
            <label for="inputMessage" class="col-form-label"
              i18n="Contact form message@@contact.form.message">Message</label>
            <textarea class="form-control bg-secondary text-light"
              rows="6" id="inputMessage" name="inputMessage" #inputMessage="ngModel"
              i18n-aria-label="Contact form message@@contact.form.input.message" aria-label="Your Message..."
              required ngModel [class.is-invalid]="inputMessage.invalid"></textarea>
          </div>
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
              <h4 class="alert-heading" i18n="Contact form error title@@contact.form.error.title">Error sending message</h4>
              <p i18n="Contact form error occured message@@contact.form.error.message">An error occured while sending your message.</p>
              <hr>
              <p class="mb-0">{{ errorMessage }}</p>
          </div>
          <div class="alert alert-success" role="alert" *ngIf="messageSent">
              <h4 class="alert-heading" i18n="Contact form sent title@@contact.form.sent.title">Message sent!</h4>
              <p i18n="Contact form sent thanks@@contact.form.sent.thanks">Thanks for getting in touch with me.</p>
              <hr>
              <p class="mb-0" i18n="Contact form sent get back message@@contact.form.sent.message">I'll get back to you as soon as possible.</p>
          </div>
          <div class="modal-footer">
            <button id="submitContactButton" type="submit" class="btn btn-primary"
              i18n="Contact form name@@contact.form.send" [disabled]="contactForm.invalid" [class.disabled]="contactForm.invalid" aria-disabled="contactForm.invalid">Send</button>
          </div>
        </form>
      </div>


    </div>
  </div>
</div>