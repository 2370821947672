<div class="modal fade" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="orderModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bg-secondaryx bg-dark text-secondary">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Order form
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal"
          i18n-aria-label="Contact form message@@contact.form.close" aria-label="Close">
        </button>
      </div>
      <div id="formBody" class="modal-body">
        <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
        <div class="row form-row">
          <div class="col-md-6">
            <label for="inputName" class="col-form-label">Name</label>
            <input type="text" class="form-control bg-secondary text-light" id="inputName" name="inputName" #inputName="ngModel"
              aria-label="Your Name" required ngModel [class.is-invalid]="inputName.invalid">
          </div>
          <div class="col-md-6">
            <label for="inputEmail" class="col-form-label">Email (paypal adress)</label>
            <input type="email"
              class="form-control bg-secondary text-light" id="inputEmail" name="inputEmail" #inputEmail="ngModel"
              aria-label="Your Email" required email ngModel  [class.is-invalid]="inputEmail.invalid">
          </div>
        </div>
        <div class="row form-row">
          <div class="col-md-6">
            <label for="inputVersion" class="col-form-label">Version</label>
            <select class="custom-select form-control bg-secondary text-light"
                id="inputVersion" name="inputVersion" #inputVersion="ngModel"
                aria-label="Version" required ngModel  [class.is-invalid]="inputVersion.invalid">
                <option value="Version1-Uri-Steeve">Version 1 - Uri and Steeve’s edit</option>
                <option value="Version2-Uri">Version 2 - Uri’s edit</option>
                <option value="Version3-Steeve">Version 3 - Steeve’s edit</option>
                <option value="Version4-William">Version 4 - William’s edit</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="inputSize" class="col-form-label">Size</label>
            <select class="custom-select form-control bg-secondary text-light"
                id="inputSize" name="inputSize" #inputSize="ngModel"
                aria-label="Size" required ngModel  [class.is-invalid]="inputSize.invalid">
                <option value="A3">A3 297 mm x 420 mm. (11.69 in. x 16.54 in.)</option>
                <option value="A2">A2 420 mm x 594 mm. (16.54 in. x 23.39 in.)</option>
                <option value="A1">A1 594 mm x 841 mm. (23.39 in. x 33.11 in.)</option>
            </select>
          </div>
        </div>
        <div class="row form-row">
          <div class="col-md-6">
            <label for="inputSupport" class="col-form-label">Support</label>
            <select class="custom-select form-control bg-secondary text-light"
                id="inputSupport" name="inputSupport" #inputSupport="ngModel"
                aria-label="Support" required ngModel  [class.is-invalid]="inputSupport.invalid">
                <option value="FineArt">Fine art print (no support)</option>
                <option value="Dibond">Alu Dibond</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="inputFormat" class="col-form-label">Format</label>
            <select class="custom-select form-control bg-secondary text-light"
                id="inputFormat" name="inputFormat" #inputFormat="ngModel"
                aria-label="Size" required ngModel  [class.is-invalid]="inputFormat.invalid">
                <option value="Full">Full image</option>
                <option value="Crop30">Crop 30x30</option>
                <option value="Crop40">Crop 40x40</option>
                <option value="Crop50">Crop 50x60</option>
            </select>
          </div>
        </div>
        <div class="row form-row"><h5 class="mt-3">Shipping Address</h5></div>
          <div class="form-group">
            <label for="inputStreet" class="col-form-label">Street</label>
            <input type="text" class="form-control bg-secondary text-light" id="inputStreet" name="inputStreet" #inputStreet="ngModel"
              aria-label="Shipping Street" required ngModel [class.is-invalid]="inputStreet.invalid">
          </div>
        <div class="row form-row">
          <div class="col-md-6">
            <label for="inputZip" class="col-form-label">Postal code</label>
            <input type="text" class="form-control bg-secondary text-light" id="inputZip" name="inputZip" #inputZip="ngModel"
              aria-label="Shipping Postal Code" required ngModel [class.is-invalid]="inputZip.invalid">
          </div>
          <div class="col-md-6">
            <label for="inputCity" class="col-form-label">City</label>
            <input type="text"
              class="form-control bg-secondary text-light" id="inputCity" name="inputCity" #inputCity="ngModel"
              aria-label="Shipping City" required ngModel  [class.is-invalid]="inputCity.invalid">
          </div>
        </div>
        <div class="row form-row">
          <div class="col-md-6">
            <label for="inputState" class="col-form-label">State</label>
            <input type="text" class="form-control bg-secondary text-light" id="inputState" name="inputState" #inputState="ngModel"
              aria-label="Shipping State" ngModel [class.is-invalid]="inputState.invalid">
          </div>
          <div class="col-md-6">
            <label for="inputCountry" class="col-form-label">Country</label>
            <input type="text"
              class="form-control bg-secondary text-light" id="inputCountry" name="inputCountry" #inputCountry="ngModel"
              aria-label="Shipping Country" required ngModel  [class.is-invalid]="inputCountry.invalid">
          </div>
        </div>
          <div class="form-group">
            <label for="inputAdditional" class="col-form-label">Additional information</label>
            <textarea class="form-control bg-secondary text-light"
              rows="3" id="inputAdditional" name="inputAdditional" #inputAdditional="ngModel" aria-label="Additional information" ngModel></textarea>
          </div>
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
              <h4 class="alert-heading" i18n="Contact form error title@@contact.form.error.title">Error sending order</h4>
              <p i18n="Contact form error occured message@@contact.form.error.message">An error occured while sending your order.</p>
              <hr>
              <p class="mb-0">{{ errorMessage }}</p>
          </div>
          <div class="alert alert-success" role="alert" *ngIf="messageSent">
              <h4 class="alert-heading" i18n="Contact form sent title@@contact.form.sent.title">Order received!</h4>
              <p i18n="Contact form sent thanks@@contact.form.sent.thanks">Thanks for you order.</p>
              <hr>
              <p class="mb-0" i18n="Contact form sent get back message@@contact.form.sent.message">We'll get back to you as soon as possible.</p>
          </div>
          <div class="modal-footer">
            <button id="submitContactButton" type="submit" class="btn btn-primary"
              i18n="Contact form name@@contact.form.send" [disabled]="contactForm.invalid" [class.disabled]="contactForm.invalid" aria-disabled="contactForm.invalid">Send</button>
          </div>
        </form>
      </div>


    </div>
  </div>
</div>