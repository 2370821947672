import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private lang: string;
  private translations: Map<string, string> = new Map<string, string>();
  private loadedFiles: string[] = [];

  constructor(private http: HttpClient) {
  }

  setLang(lang:string) {
    if(this.lang != lang) {
      this.lang = lang;
      for(let file of this.loadedFiles) {
        this.loadTranslations(file).then(result => {});
      }
    }
  }

  getLang(): string {
    return this.lang;
  }

  loadTranslations(file: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
//      const langPath = `../${this.lang || 'en'}/assets/i18n/${this.lang || 'en'}/${file}.json`;
      const langPath = `../${this.lang || 'en'}/assets/i18n/${file}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          let translationJson: any;
          translationJson = Object.assign({}, translation || {});
          Object.keys(translationJson).forEach(key => this.translations.set(key, translationJson[key]));
          this.loadedFiles.push(file);
          resolve(this.translations);
        },
        error => {
          //this.translations.clear();
          resolve(this.translations);
        }
      );
    });
  }

  loadJsonTranslations(jsonTranslations: any) {
    Object.keys(jsonTranslations).forEach(key => this.translations.set(key, jsonTranslations[key]));
  }

  translate(key: string): string {
    let translation: string;
    translation = this.translations.get(key);
    if(translation) {
      return translation;
    }
    return key;
  }

}
