import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/m31.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-m31',
  templateUrl: './m31.component.html',
  styleUrls: ['./m31.component.sass']
})
export class M31Component {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;

}
