<div class="container-fluid bg-dark">
    <div class="container text-light">
        <div *ngFor="let row of rows; let i = index; let odd = odd;" [attr.data-index]="i" class="pb-3">
            <div [class]="'mb-3 bg-dark border-secondary translate-in-from-' + (odd ? 'left' : 'right') + '-delayed-' + i"
                 style="opacity: 0;">
                <div class="row no-gutters justify-content-center" style="min-height: 240px;">
                    <div *ngIf="row.image != undefined" [class]="'col-8 col-md-4 align-self-center' + (odd ? ' order-md-last' : '') ">
                        <img src="{{row.image}}" class="col-md-2 card-img mx-auto d-block rounded"
                            style="max-width: 400px">
                    </div>
                    <div [class]="row.image != undefined ? 'col-md-8' : 'col-12'" style="position: relative;">
                        <div class="card-body">
                            <h4 [class]="'card-title row justify-content-between text-' + (row.image != undefined ? (odd ? 'end' : 'start') : 'center')">
                                <span>{{ row.title }}</span>
                            </h4>
                            <hr class="featurette-divider">
                            <h5 [class]="'card-text position-relative text-' + (row.image != undefined ? 'start' : 'center')" [innerHTML]="row.textHtml" style="z-index: 3;"></h5>
                            <div class="row text-center card-img-overlay d-none d-xxl-flex align-items-end p-4">
                                <hr *ngIf="row.buttons !=undefined && row.buttons.length > 0" class="featurette-divider visible-xs pb-2">
                            </div>
                            <div class="row card-img-overlay d-none d-xxl-flex align-items-end px-2">
                                <div class="d-inline-flex flex-wrap gap-2">
                                    <div *ngFor="let button of row.buttons" [class]="button.divClass">
                                        <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'mx-1 text-nowrap btn ' + button.class">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                        <a *ngIf="button.url != undefined" [href]="button.url" [class]="'mx-1 text-nowrap btn ' + button.class">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row text-center d-xxl-none px-2" style="height: 8px;">
                                <hr *ngIf="row.buttons !=undefined && row.buttons.length > 0" class="featurette-divider visible-xs">
                            </div>
                            <div class="row d-xxl-none">
                                <div class="d-inline-flex flex-wrap gap-2 px-2">
                                    <div *ngFor="let button of row.buttons" [class]="button.divClass">
                                        <a *ngIf="button.routerLink != undefined" [routerLink]="button.routerLink" [class]="'text-nowrap btn ' + button.class">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                        <a *ngIf="button.url != undefined" [href]="button.url" [class]="'text-nowrap btn ' + button.class">
                                            <i *ngIf="button.iconClass != undefined" [class]="'fa fa-fw ' + button.iconClass" style="height: 20px;"></i>
                                            <img *ngIf="button.iconUrl != undefined" [src]="button.iconUrl" style="height: 24px; padding-bottom: 2px;">
                                            &nbsp;{{ button.text }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br>
            </div>
        </div>
    </div>
</div>
