import { Component, OnInit,  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.sass']
})
export class OrderComponent {

  public messageSent: boolean = false;
  public errorMessage: string = '';

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.messageSent = false;
    this.errorMessage = '';
  }

  onSubmit(form: NgForm) {
    let message = "\n" +
      "Version: " + form.value.inputVersion + "\n" +
      "Size: " + form.value.inputSize + "\n" +
      "Support: " + form.value.inputSupport + "\n" +
      "Format: " + form.value.inputFormat + "\n" +
      "\n" +
      "Street: " + form.value.inputStreet + "\n" +
      "Zip: " + form.value.inputZip + "\n" +
      "City: " + form.value.inputCity + "\n" +
      "State: " + form.value.inputState + "\n" +
      "Country: " + form.value.inputCountry + "\n" +
      "\n" +
      "Additional: " + form.value.inputAdditional;

    const payload = new HttpParams()
      .set('inputName', form.value.inputName)
      .set('inputEmail', form.value.inputEmail)
      .set('inputSubject', "[DSC Website - Order] - " + form.value.inputVersion + " - " + form.value.inputSize + " - " + form.value.inputSupport + " - " + form.value.inputFormat)
      .set('inputMessage', message);
    this.http.post('../..//assets/php/contact-form.php', payload, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).subscribe(result => {
      if(result['success']) {
        this.onSuccess(form);
      } else {
        this.onError(result['message']);
      }
    },
    error => {
      this.onError(error.message);
    });
  }

  onError(message: string) {
    this.errorMessage = message;
  }

  onSuccess(form: NgForm) {
    this.messageSent = true;
    form.reset();
  }

}
