<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/M51_2024_HaLRGB.dzi', 'assets/astro-img/M51_2024_LRGB.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/M51_2024_Annotations/'"
                    [imageWidth]="5549"
                    [imageHeight]="4078" 
                    [imageScale]="0.75"
                    [imageVersions]="['HaLRGB', 'LRGB']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'L', brand: '', exposures: [{ count: 1176, total: 395550 }] },
                                        { filter: 'R', brand: '', exposures: [{ count: 114, total: 56220 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 94, total: 40980 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 95, total: 42900 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 1058, total: 1378800 }] }]"
                    [nights]="{ count: 158, start: '2017-02-18', end: '2024-07-19' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['AG Optical FA12 12.5 inch F5' , 'Askar 130PHQ' , 'Astro-Physics StarFire EDFS 130mm f6' , 'Planewave CDK17' , 'Sky-Watcher Esprit 150ED' , 'Sky-Watcher Quattro 250P' , 'Sky-Watcher Quattro 8CF' , 'Stellarvue SVX130T' , 'Takahashi FSQ-106EDX4' , 'Takahashi TOA-150' , 'TEC APO140ED' , 'TS-Optics 200mm' , 'ONTC f4.5 Newtonian (carbon tube)' , 'TS-Optics 200mm ONTC f4 Newtonian (carbon tube)' , 'TS-Optics 250mm ONTC f4 Newtonian (carbon tube)' , 'TS-Optics ED 102mm f7'],
                                    cameras: ['Player One Ares-M Pro' , 'Player One Poseidon-M Pro' , 'QHYCCD QHY268 M' , 'QHYCCD QHY600PH M' , 'RisingCam IMX571' , 'ZWO ASI1600MM' , 'ZWO ASI2600MM Pro' , 'ZWO ASI294MM Pro' , 'ZWO ASI461MM Pro' , 'ZWO ASI6200MM Pro'] }">
</app-astro-photo>
