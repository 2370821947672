<div class="container-fluid bg-dark">
    <div class="container bg-dark">
        <div class="card mb-3 bg-dark">
            <img class="card-img translate-in-from-right" src="assets/img/excavator-406812_1920.jpg" alt="">
            <div class="card-img-overlay text-white d-none d-sm-block">
                <h2 class="card-title translate-in-from-right" i18n="Under construction title@@under-construction.title">
                    Under construction
                </h2>
                <hr class="featurette-divider">

                <h4 class="card-subtitle px-0 justify-content-between translate-in-from-left"
                    i18n="Under construction message@@under-construction.message">
                    this page is currently being developped and will be available shortly
                </h4>
            </div>
            <div class="card-body text-white d-block d-sm-none translate-in-from-right">
                <h2 class="card-title translate-in-from-top" i18n="Under construction title@@under-construction.title">
                    Under construction
                </h2>
                <hr class="featurette-divider">

                <h4 class="card-subtitle px-0 justify-content-between translate-in-from-bottom"
                    i18n="Under construction message@@under-construction.message">
                    this page is currently being developped and will be available shortly
                </h4>
            </div>
        </div>
    </div>
</div>