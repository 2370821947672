<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/M31-ARC_Uri-Steeve.dzi', 'assets/astro-img/M31-ARC_Uri.dzi', 'assets/astro-img/M31-ARC_Steeve.dzi', 'assets/astro-img/M31-ARC_William.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/M31-ARC_Annotations/'"
                    [imageWidth]="6000"
                    [imageHeight]="4159" [imageScale]="1.15"
                    [imageVersions]="['uri-steeve', 'uri', 'steeve', 'william']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'L', brand: '', exposures: [{ count: 247, total: 95700 }] },
                                        { filter: 'R', brand: '', exposures: [{ count: 448, total: 62100 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 491, total: 94650 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 407, total: 56250 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 1344, total: 1130700 }] },
                                        { filter: 'Oiii', brand: '', exposures: [{ count: 2016, total: 1755900 }] },
                                        { filter: 'Sii', brand: '', exposures: [{ count: 703, total: 608100 }] }]"
                    [nights]="{ count: 96, start: '2021-08-27', end: '2023-12-06' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['APM Apo 107/700', 'Askar FRA600', 'Celestron C9.25 SC XLT', 'Celestron RASA 8”', 'CFF Telescopes Refractor 135mm f/6.7', 'Sky-Watcher Equinox 80', 'Sky-Watcher Esprit 100ED', 'Sky-Watcher Esprit 150ED', 'Stellarvue SVX130T', 'Takahashi FSQ-106EDX4', 'TS-Optics Photoline 140mm f/6.5', 'William Optics Fluorostar 120 / FLT120', 'William Optics Fluorostar 132 / FLT132', 'William Optics ZenithStar 81 / ZS81'],
                                    cameras: ['QHYCCD QHY600PH M', 'RisingCam ATR3-26000KMA', 'ZWO ASI2600MC Pro', 'ZWO ASI2600MM Pro', 'ZWO ASI294MM Pro', 'ZWO ASI6200MM Pro' ] }">
</app-astro-photo>
