<div class="container-fluid bg-dark">
      <div class="container bg-dark">

            <div class="row">
                  <a *ngFor="let target of targets; let i = index; let odd = odd;" [attr.data-index]="i"
                        class="col-12 col-sm-6 col-lg-4 p-0" style="text-decoration: none;"
                        [attr.href]="target.url != '.' ? target.url : null">
                        <div>
                              <div [class]="'card p-2 m-1 border-light text-light bg-dark bg-image translate-in-from-' + (odd ? 'right' : 'left') + '-delayed-' + i"
                                    [ngStyle]="{backgroundImage:'url(' + target.image + ')'}"
                                    style="background-repeat: no-repeat; background-size: cover; background-position: center; min-height: 262px; opacity: 0;">
                                    <h3 class="text-center ">
                                          <span>{{ target.title }}</span>
                                    </h3>
                                    <!-- <hr class="featurette-divider visible-xs"> -->
                                    <h6 class="text-center "><i [innerHTML]="target.tagline"></i></h6>
                                    <div class="card-body d-flex align-items-end">
                                          <div class="card" style="width: 100%; opacity: 0.75;">
                                                <ul class="p-1 bg-dark list-group list-group-flush in"
                                                      >
                                                      <li class="p-1 bg-dark list-group-item waves-light text-white"
                                                            mdbWavesEffect *ngFor="let detail of target.details"
                                                            [innerHTML]="detail">
                                                      </li>
                                                </ul>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </a>
            </div>

            <!-- <app-navlinks previousLink="/home" previousTitle="Home" previousIcon="fa-home"
                  i18n-previousTitle="Home page title@@services.home" nextLink="/downloads" nextTitle="Downloads"
                  nextIcon="fa-download" i18n-nextTitle="Astronomy page title@@services.astronomy"></app-navlinks> -->

      </div>
</div>