import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/m82-apgp.json';

@Component({
  selector: 'app-m82-apgp',
  templateUrl: './m82-apgp.component.html',
  styleUrls: ['./m82-apgp.component.sass']
})
export class M82ApgpComponent {

  target: any = jsonTarget;
}