import { Component } from '@angular/core';
import jsonAbout from '../../../locale/data/about.json';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent {
  rows: any = jsonAbout;

  constructor() { }

  ngAfterViewInit() {
    $('a').on("click",(e)=>{
      if((<any>e.currentTarget).hash.startsWith('#')) {
        e.preventDefault();
        document.querySelector((<any>e.currentTarget).hash).scrollIntoView({ behavior: "smooth" }); //, block: "center"
      }
    });
  }

}
