<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/Collaboration_M82.dzi', 'assets/astro-img/Collaboration_M82_HII.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/Collaboration_M82_Annotations/'"
                    [imageWidth]="6000"
                    [imageHeight]="4200" [imageScale]="0.674"
                    [imageVersions]="['natural', 'hii']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'L', brand: '<b>OSC-BB: </b>Antila Triband Ultra RGB, Optolong L-Ultimate, Optolong L-Pro', exposures: [{ count: 1122, total: 147420 }] },
                                        { filter: 'R', brand: '<b>OSC-NB: </b>Askar D1 6nm Ha/Oiii', exposures: [{ count: 2733, total: 130185 }] },
                                        { filter: 'G', brand: '<br><b>Mono-BB: </b>Antlia V-Series LRGB, Astronomik LRGB, Baader LRGB, Chroma LRGB', exposures: [{ count: 2694, total: 216378 }] },
                                        { filter: 'B', brand: '<b>Mono-NB: </b>Antlia Ha pro series 3nm, Baader Ha 6.5nm, Baader Ha 7nm', exposures: [{ count: 2736, total: 130260 }] },
                                        { filter: 'Ha', brand: 'Baader Highspeed Ha, Chroma Ha 5nm, Optolong Ha 7nm, ZWO Ha 7nm', exposures: [{ count: 3014, total: 791190 }] }]"
                    [nights]="{ count: -1, start: '2021-12-31', end: '2023-03-21' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['8&quot; RC Carbontube', '8&quot; Newton', '10&quot; ONTC f4 Newton', 'Celestron RASA 11', 'Custom f/4.5 8&quot; Newton', 'Explore Scientific ED127', 'Meade S6000 115mm', 'Skywatcher 200PDS', 'Sky-Watcher Esprit 100ED', 'Sky-Watcher Esprit 150ED', 'Skywatcher Quattro 8s', 'TPO 8&quot; Newton', 'TS-Optics PHOTOLINE 130', 'William Optics FLT120', 'William Optics FLT132'],
                                    cameras: ['<b>OSC: </b>Rising Cam IMX571 OSC', 'ZWO ASI294MC-Pro', 'ZWO ASI2600MC', '<br><b>Mono: </b>QHY 163M', 'QHY 268M', 'Touptek IMX571 Mono', 'ZWO ASI1600MM', 'ZWO ASI294MM-Pro', 'ZWO ASI2600MM', 'ZWO ASI6200MM'] }">
</app-astro-photo>