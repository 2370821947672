import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/gkpersei.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-gkpersei',
  templateUrl: './gkpersei.component.html',
  styleUrls: ['./gkpersei.component.sass']
})
export class GKPerseiComponent {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;

}
