import { Component } from '@angular/core';
import jsonRows from '../../../locale/data/blogs.json';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.sass']
})
export class BlogComponent {
  rows: any = jsonRows;

}
