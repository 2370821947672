<!--<div class="bg-light text-dark text-center p-4 m-0">-->
<footer class="bg-dark text-light text-center p-4 container-fluid">
  <div class="row">
    <div class="col-sm-2 col-md-3">
      <p class="pull-left"><small class="text-muted" i18n="Footer last updated date@@footer.lastUpdated">Last updated {{ buildDate }}</small></p>
    </div>
    <div class="col-sm-8 col-md-6">
      <p>
        <span i18n>©&nbsp;{{ currentYear() }} Deep Sky Collective</span>
        &nbsp;·&nbsp;
        <a class="navbar-brand" href="https://elveteek.ch">
          Design <img class="img-responsive d-inline-block align-text-top" src="assets/img/elveteek-logo-gray-sd120.png" height="24px">
        </a>
        &nbsp;·&nbsp;
        <a class="btn btn-secondary rotate-360-repeat-delayed-5s" href="#" role="button" data-bs-toggle="modal"
          data-bs-target="#contactModal">Contact</a>
      </p>
    </div>
    <div class="col-sm-2 col-md-3">
      <p class="pull-right"><a (click)="scrollToTop()" class="text-light cursor-pointer"
          i18n="Footer back to top@@footer.backToTop">Back to top</a></p>
    </div>
  </div>
</footer>
<!-- </div> -->