import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/m51v2.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-m51v2',
  templateUrl: './m51v2.component.html',
  styleUrls: ['./m51v2.component.sass']
})
export class M51v2Component {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;
}
