<nav id="navbar" class="navbar navbar-expand-sm navbar-dark bg-dark">
  <div class="container">
    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" routerLinkActive="active" routerLink="/home">
      <i class="fa fa-fw fa-house" style="height: 20px;"></i>&nbsp;DSC
      <!-- <img class="img-responsive d-inline-block align-text-top" src="assets/img/dsclogo/xs-logo/dsc-logo32x32.png" height="24px"> -->
    </a>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/gallery"><i class="fa fa-fw fa-images" style="height: 20px;"></i>&nbsp;Gallery</a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/tutorials"><i class="fa fa-fw fa-swatchbook" style="height: 20px;"></i>&nbsp;Tutorials</a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/blog"><i class="fa fa-fw fa-clipboard-list" style="height: 20px;"></i>&nbsp;Blog</a>
        </li>
        <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/prints"><i class="fa fa-fw fa-basket-shopping" style="height: 20px;"></i>&nbsp;Prints</a>
        </li> -->
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link" routerLinkActive="active" routerLink="/about"><i class="fa fa-fw fa-info-circle" style="height: 20px;"></i>&nbsp;About</a>
        </li>
      </ul>
    </div>

  </div>
</nav>