<div class="container-fluid bg-dark">
    <div class="container bg-dark text-light">
        <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
                <nav id="navbar-tutorial"
                    class="navbar navbar-dark bg-dark text-light flex-column align-items-stretch p-3"
                    style="height: 80vh; position: relative; overflow: auto;">
                    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
                    <nav class="nav nav-pills flex-column">
                        <a class="nav-link collapsible-scrollspy" href="#intro">Intro</a>
                        <nav class="nav nav-pills flex-column">
                            <a class="nav-link ms-3 my-1" href="#intro-1">How it came to be</a>
                            <a class="nav-link ms-3 my-1" href="#intro-2">Why we do this guide</a>
                        </nav>
                        <a class="nav-link" href="#team">Putting a team together</a>
                        <nav class="nav nav-pills flex-column">
                            <a class="nav-link ms-3 my-1" href="#team-criteria">Criteria (quality, setup,
                                location...)</a>
                            <!-- <nav class="nav nav-pills flex-column">
                                <a class="nav-link ms-5 my-1" href="#team-criteria-quality">Quality</a>
                                <a class="nav-link ms-5 my-1" href="#team-criteria-setup">Setup</a>
                                <a class="nav-link ms-5 my-1" href="#team-criteria-location">Location</a>
                            </nav> -->
                            <a class="nav-link ms-3 my-1" href="#team-people">Types of people</a>
                        </nav>
                        <a class="nav-link" href="#choose-target">Choosing a target</a>
                        <nav class="nav nav-pills flex-column">
                            <a class="nav-link ms-3 my-1" href="#choose-target-1">Voting, interesting features</a>
                            <a class="nav-link ms-3 my-1" href="#choose-target-2">Setups, FOV and locations link</a>
                        </nav>

                        <a class="nav-link" href="#coordinate-group">How to coordinate the group</a>
                        <nav class="nav nav-pills flex-column">
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-1">Clear communication</a>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-2">Make it easy to understand, give
                                details</a>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-3">Push certain things s.a framing</a>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-4">get a platform to work on
                                (discord)</a>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-5">Getting things started</a>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-6">Letting things go on by itself</a>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-7">Check-ins with example</a>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-8">Fix deadline in advance</a>
                            <nav class="nav nav-pills flex-column ms-4">
                                <a class="nav-link my-1" href="#coordinate-group-8-1">Uploading data</a>
                                <nav class="nav nav-pills flex-column ms-4">
                                    <a class="nav-link my-1" href="#coordinate-group-8-1-1">Fix a platform where to
                                        upload</a>
                                    <nav class="nav nav-pills flex-column ms-4">
                                        <a class="nav-link my-1" href="#coordinate-group-8-1-1-1">Best case NAS, GD also
                                            works</a>
                                    </nav>
                                </nav>
                                <a class="nav-link my-1" href="#coordinate-group-8-2">Start stacking > next section of
                                    guide</a>
                            </nav>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-9">General tips</a>
                            <nav class="nav nav-pills flex-column ms-4">
                                <a class="nav-link my-1" href="#coordinate-group-9-1">Atmosphere, have fun</a>
                            </nav>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-10">Stacking & technical part >
                                William and Carl</a>
                            <a class="nav-link ms-3 my-1" href="#coordinate-group-11">Outro/final word</a>
                        </nav>

                    </nav>
                </nav>
            </div>
            <div class="col-lg-9">
                <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-offset="0" tabindex="0"
                    style="height: 80vh; position: relative; overflow: auto;">
                    <h2 id="intro" class="text-center">Intro</h2>
                    <p class="lead">Tagline.</p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <h3 id="intro-1" class="text-center mt-5">How it came to be</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <h3 id="intro-2" class="text-center mt-5">Why we do this guide</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>

                    <h2 id="team" class="text-center">Putting a team together</h2>
                    <h3 id="team-criteria" class="text-center mt-5">Criteria</h3>
                    <h4 id="team-criteria-quality" class="text-center mt-5">Quality</h4>
                    <h4 id="team-criteria-setup" class="text-center mt-5">Setup</h4>
                    <h4 id="team-criteria-loacation" class="text-center mt-5">Location</h4>
                    <h3 id="team-people" class="text-center mt-5">Types of people</h3>

                    <h2 id="choose-target" class="text-center">Choosing a target</h2>
                    <h3 id="choose-target-1" class="text-center mt-5">Voting, interesting features</h3>
                    <h3 id="choose-target-1" class="text-center mt-5">Setups, FOV and locations link</h3>

                    <h2 id="coordinate-group" class="text-center">How to coordinate the group</h2>
                    <h3 id="coordinate-group-1" class="text-center mt-5">clear communication</h3>
                    <h3 id="coordinate-group-2" class="text-center mt-5">make it easy to understand, give details</h3>
                    <h3 id="coordinate-group-3" class="text-center mt-5">push certain things s.a framing</h3>
                    <h3 id="coordinate-group-4" class="text-center mt-5">get a platform to work on (discord)</h3>
                    <h3 id="coordinate-group-5" class="text-center mt-5">Getting things started</h3>
                    <h3 id="coordinate-group-6" class="text-center mt-5">Letting things go on by itself</h3>
                    <h3 id="coordinate-group-7" class="text-center mt-5">Check-ins with example</h3>
                    <h3 id="coordinate-group-8" class="text-center mt-5">Fix deadline in advance</h3>
                    <h4 id="coordinate-group-8-1" class="text-center mt-5">Uploading data</h4>
                    <h5 id="coordinate-group-8-1-1" class="text-center mt-5">Fix a platform where to upload</h5>
                    <h6 id="coordinate-group-8-1-1-1" class="text-center mt-5">Best case NAS, GD also works</h6>
                    <h5 id="coordinate-group-8-2" class="text-center mt-5">Start stacking > next section of guide</h5>
                    <h3 id="coordinate-group-9" class="text-center mt-5">General tips</h3>
                    <h4 id="coordinate-group-9-1" class="text-center mt-5">Atmosphere, have fun</h4>
                    <h3 id="coordinate-group-10" class="text-center mt-5">Stacking & technical part > William and Carl
                    </h3>
                    <h3 id="coordinate-group-11" class="text-center mt-5">Outro/final word</h3>
                    <!--
- putting a team together
    - criteria (quality, setup, location...)
    - Types of people
- Choosing a target
    - Voting, interesting features
    - Setups, FOV and locations link
- How to coordinate the group
    - clear communication
    - make it easy to understand, give details
    - push certain things s.a framing
        - — examples
    - get a platform to work on (discord)
        - clear channels/organisation to keep things apart
        - > examples
    - Getting things started
        - Lots of explanations
    - Letting things go on by itself
    - Check-ins with example
        - Estimate where you are at, what needs more work etc
    - Fix deadline in advance
        - Uploading data
            - Fixa platform where to upload
                - Best case NAS, GD also works
        - Start stacking > next section of guide
- General tips
    - Atmosphere,have fun
- Stacking & technical part > William and Carl
- Outro/final word
-->

                    <a style="text-decoration: none;">
                        <div class="card bg-dark text-light text-center">
                            <div class="card-body">
                                <img class="card-img" src="assets/img/M45_LRGB5_11_1320.jpg" alt="">
                            </div>
                            <div class="card-footer text-muted">
                                <h2 i18n>
                                    The Pleiades, M45.
                                </h2>
                                <p i18n>
                                    The seven sisters, Asterope, Merope, Electra, Maia, Taygeta, Celaeno and Alcyone are
                                    the daughters of Atlas and Pleione in Ancient Greek.
                                </p>
                            </div>
                        </div>
                    </a>

                    <figure class="text-light p-4"
                        style="border-left: .35rem solid #fcdb5e; border-top: 1px solid #eee; border-right: 1px solid #eee; border-bottom: 1px solid #eee;">
                        <blockquote class="pb-2">
                            <div class="row">
                                <div class="col-auto">
                                    <i class="fa fa-quote-left fa-2x mb-4 me-2 astro-tagline float-start"
                                        style="color: #fcdb5e;"></i>
                                    <p class="lead">
                                        The Pleiades also known as M45, is an open star cluster visible from both
                                        hemispheres in the constellation Taurus.
                                        The exact distance of the cluster is still debated. This distance, according to
                                        different technical instruments used, is estimated to approximately 444 light
                                        years.</p>
                                </div>
                            </div>
                            <h4 i18n>Origin of name</h4>
                            <p i18n>The name of the Pleiades comes from Ancient Greek : The Pleiades are seven sisters,
                                daughters of Atlas and Pleine : Asterope (aka Sterope), Merope, Electra, Maia, Taygeta,
                                Celaeno and Alcyone.</p>
                            <p i18n>
                                They have been known since antiquity to cultures all around the world, and they have
                                other names according to different cultures.<br>
                                Tuareg Berbers of the northern Sahara call the Pleiades Cat iheḍ. This Berber name
                                means: "daughters of the night"
                            </p>
                            <p i18n>« Cat ahăḍ as uḍănăt, ttukayeɣ ttegmyeɣ, anwar daɣ ttsasseɣ. As d-gmaḍent, ttukayeɣ
                                ttegmyeɣ tabruq ttelseɣ. »<br>
                                <i>When the Pleiades fall, I wake looking for my goatskin bag to drink. When (the
                                    Pleiades) rise, I wake looking for cloth/clothes to wear.</i>
                                <br>
                                — Tuareg Berber proverb
                            </p>
                            <p i18n>This constellation is the sailers boss and is associated with winds.</p>
                            <p i18n>The myth of Orion chasing The Pleiades could be paleolithic and could date from
                                human leaving Africa, as many studies in the field of comparative mythology have shown.
                            </p>
                            <h4 i18n>Stars</h4>
                            <p i18n>
                                The cluster contains approximately 3000 stars, twelve are visible to the naked eye.
                                Its radius is 2°, approximately 4 times of the apparent size of the moon.
                                Therfore its density is low compared to other open stars clusters.
                                The cluster is estimated to be 100 million years old, but it will notz live for long as
                                it should separate in 250 million years,
                                in part due to its low density (we are talking about the life of the cluster and not of
                                the stars it is composed of).
                            </p>
                            <p i18n>
                                The 9 brigthest stars have their names from the name of the 7 sisters and their parents.
                                Their apparent magnitude is beteween 2,86 and 5,44, so they are visible to the naked
                                eye.
                                Asterope has the special characteristic that it is a binary star.
                            </p>
                        </blockquote>
                        <figcaption class="blockquote-footer mb-0">
                            <p i18n>source translated from french Wikipedia article in <cite><a
                                        href="https://fr.wikipedia.org/wiki/Pl%C3%A9iades_(astronomie)">https://fr.wikipedia.org/wiki/Pl%C3%A9iades_(astronomie)</a></cite>
                            </p>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    </div>
    <br />
</div>