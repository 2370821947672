import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/m106.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-m106',
  templateUrl: './m106.component.html',
  styleUrls: ['./m106.component.sass']
})
export class M106Component {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;
}
