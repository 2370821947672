import { Component, OnInit, Inject } from '@angular/core';
// import { ScrollSpy } from 'bootstrap'; // /!\ doing this will cause double import of bootstrap and cause issues such as navbar not collapsing

@Component({
  selector: 'app-running-group-project',
  templateUrl: './running-group-project.component.html',
  styleUrls: ['./running-group-project.component.sass']
})
export class RunningGroupProjectComponent {

  ngAfterViewInit() {
    // var scrollSpy = new ScrollSpy(document.body, {
    //   target: '#navbar-tutorial',
    //   offset: 56,
    // });
    // var scrollSpyEl = (<any>$('body')).scrollspy({ target: '#navbar-tutorial' }); // TODO does not work here but does in the console (still with issue that first chapters are highlited only at first pass)
    // console.log(scrollSpyEl);
    // var scrollSpy = ScrollSpy.getInstance(scrollSpyEl);
    // console.log(scrollSpy);
    $('.nav>a').on("click",(e)=>{
      e.preventDefault();
      document.querySelector((<any>e.currentTarget).hash).scrollIntoView({ behavior: "smooth", block: "center" });
    });
    // scrollSpy.refresh();
  }
}
