<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/M106_DSC_f.dzi', 'assets/astro-img/M106_DSC_rxi.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/M106_DSC_Annotations/'"
                    [imageWidth]="6110"
                    [imageHeight]="4076" [imageScale]="0.5"
                    [imageVersions]="['lrgbhoo', 'multiwave']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'L', brand: '', exposures: [{ count: 1412, total: 509040 }] },
                                        { filter: 'R', brand: '', exposures: [{ count: 422, total: 154920 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 374, total: 130980 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 368, total: 139050 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 1278, total: 813960 }] },
                                        { filter: 'Oiii', brand: '', exposures: [{ count: 472, total: 289800 }] }]"
                    [nights]="{ count: 69, start: '2022-12-22', end: '2024-04-18' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['Deep Sky Instruments (DSI) DSI RC14', 'Sky-Watcher Esprit 150ED', 'Sky-Watcher Quattro 8CF', 'StellaMira 90mm ED Triplet', 'Stellarvue SVX130T', 'Stellarvue SVX180T', 'TS-Optics 200mm ONTC f4.5 Newtonian (carbon tube)', 'TS-Optics 200mm ONTC f4 Newtonian (carbon tube)', 'TS-Optics 250mm ONTC f4 Newtonian (carbon tube)', 'William Optics Fluorostar 120'],
                                    cameras: ['Player One Ares-M Pro', 'QHYCCD QHY268 M', 'QHYCCD QHY600PH M', 'RisingCam ATR3-26000KMA', 'ZWO ASI2600MM Pro', 'ZWO ASI533MM Pro', 'ZWO ASI6200MM Pro'] }">
</app-astro-photo>
