import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/chain.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-chain',
  templateUrl: './chain.component.html',
  styleUrls: ['./chain.component.sass']
})
export class ChainComponent {
    target: any = jsonTarget;
    faShuttleSpace = faShuttleSpace;
  }
