<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/DSC_CasA_OSH.dzi', 'assets/astro-img/DSC_CasA_Xray.dzi', 'assets/astro-img/DSC_CasA_HOO.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/DSC_CasA_Annotations/'"
                    [imageWidth]="6007"
                    [imageHeight]="4021" [imageScale]="0.4"
                    [imageVersions]="['osh', 'xray', 'hoo']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'R', brand: '', exposures: [{ count: 178, total: 25080.2 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 192, total: 28439.04 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 151, total: 24419.72 }] },
                                        { filter: 'OIII', brand: '', exposures: [{ count: 664, total: 662406.4 }] },
                                        { filter: 'SII', brand: '', exposures: [{ count: 625, total: 564600 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 622, total: 588300.04 }] },
                                        ]"
                    [nights]="{ start: '2023-08-09', end: '2023-11-20' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['Celestron EdgeHD 11&quot;', 'Deep Sky Instruments (DSI) DSI RC14', 'Orion Optics UK CT10', 'Sky-Watcher Esprit 150ED', 'Sky-Watcher Quattro 200P', 'Sky-Watcher Quattro 250P / 10-S', 'Sky-Watcher Quattro 8CF', 'Tecnosky RC8 Carbon', 'TS-Optics ED 102mm f/7'],
                                    cameras: ['Apogee Alta U16M', 'QHYCCD QHY163M', 'QHYCCD QHY268 M', 'QHYCCD QHY600PH M', 'RisingCam ATR3-26000KMA', 'ZWO ASI1600MM', 'ZWO ASI2600MM Pro', 'ZWO ASI294MM Pro', 'ZWO ASI533MM Pro'] }">
</app-astro-photo>