<div class="container-fluid">
    <div class="container" style="align-items: center;">
  
        <div class="card mb-3 bg-secondary translate-in-from-right-delayed-0"
          style="opacity: 0;">
          <div class="row no-gutters justify-content-center">
            <div class="col-12 col-md-4 align-self-center" style="position: relative;">
                <img src="assets/img/SNR_G068.8+02.6_SHO_400.jpg" class="col-md-2 card-img mx-auto d-block">
                <div class="card-img-overlay d-flex">
                    <p class="text-white align-self-center mx-auto d-block d-md-none" style="font-size: 16vw;"><b>404</b></p>
                    <p class="text-white align-self-center mx-auto d-none d-md-block" style="font-size: 10vw;"><b>404</b></p>
                </div>
            </div>
  
            <div class="col-md-8">
              <div class="card-body">
                <h1 class="card-title row px-3 justify-content-between">
                  <span i18n>Page not found</span>
                </h1>
                <hr class="featurette-divider visible-xs">
                <h5 class="card-text"><i i18n>Sorry the page you're looking for cannot be found or is no longer available</i></h5>
              </div>
            </div>
  
          </div>
        </div>
  
    </div>
  </div>