import { Component } from '@angular/core';
import jsonRows from '../../../locale/data/prints.json';

@Component({
  selector: 'app-prints',
  templateUrl: './prints.component.html',
  styleUrls: ['./prints.component.sass']
})
export class PrintsComponent {
  rows: any = jsonRows;

}
