import { Component } from '@angular/core';

@Component({
  selector: 'app-processing-group-project',
  templateUrl: './processing-group-project.component.html',
  styleUrls: ['./processing-group-project.component.sass']
})
export class ProcessingGroupProjectComponent {

}
