<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/SNR156_Carl.dzi', 'assets/astro-img/SNR156_Steeve_FINALV7.dzi', 'assets/astro-img/SNR156_Steeve_FINALV7_RGB.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/SNR156_Annotations/'"
                    [imageWidth]="4425"
                    [imageHeight]="5109" [imageScale]="1.5"
                    [imageVersions]="['carl', 'steeve', 'steeve-rgb']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'R', brand: '', exposures: [{ count: 112, total: 20160 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 95, total: 17100 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 118, total: 21960 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 1017, total: 587400 }] },
                                        { filter: 'Oiii', brand: '', exposures: [{ count: 1079, total: 740100 }] }]"
                    [nights]="{ count: 64, start: '2023-09-27', end: '2024-04-13' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['APM Apo 107/700' , 'Sky-Watcher Equinox 80' , 'Sky-Watcher Esprit 100ED' , 'Sky-Watcher Esprit 150ED' , 'Sky-Watcher Esprit 80ED' , 'Stellarvue SVX130T' , 'Takahashi FSQ-106EDX4' , 'TS-Optics Photoline 140mm f/6.5' , 'William Optics ZenithStar 81 / ZS81'],
                                    cameras: ['Omegon veTec 571 M' , 'QHYCCD QHY268 M' , 'QHYCCD QHY600PH M' , 'RisingCam ATR3-26000KMA' , 'ZWO ASI2600MM Pro' , 'ZWO ASI294MM' , 'ZWO ASI6200MM Pro'] }">
</app-astro-photo>
