<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/Chain_HaLRGB.dzi', 'assets/astro-img/Chain_LRGB.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/Chain_Annotations/'"
                    [imageWidth]="11200"
                    [imageHeight]="3848" [imageScale]="1.1"
                    [imageVersions]="['HaLRGB', 'LRGB']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'L', brand: '', exposures: [{ count: 981, total: 497100 }] },
                                        { filter: 'R', brand: '', exposures: [{ count: 900, total: 136875 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 886, total: 183900 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 875, total: 113025 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 826, total: 729600 }] }]"
                    [nights]="{ count: 69, start: '2022-12-22', end: '2024-04-18' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['APM Apo 107/700 Askar 130PHQ', 'Celestron C9.25 SC XLT CFF Telescopes Refractor 135mm f6.7',  'Sky-Watcher Esprit 100ED', 'Sky-Watcher Esprit 150ED', 'Sky-Watcher Quattro 250P', 'StellaMira 90mm ED Triplet', 'Stellarvue SVX130T', 'Takahashi FSQ-106EDX4', 'Takahashi TOA-150', 'TS-Optics Photoline 140mm f6.5', 'William Optics ZenithStar 81'],
                                    cameras: ['QHYCCD QHY268 M', 'QHYCCD QHY600PH M', 'ZWO ASI2600MM Pro', 'ZWO ASI294MM Pro', 'ZWO ASI6200MM Pro'] }">
</app-astro-photo>
