import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import jsonGallery from '../../../locale/data/gallery.json';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.sass']
})
export class GalleryComponent implements OnInit {

  targets: any = jsonGallery;

  constructor(private meta: Meta) { }

  ngOnInit() {
  }

}
