<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/GKPersei_v8_2x.dzi', 'assets/astro-img/GKPersei_v8_LRGB.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/GKPersei_Annotations/'"
                    [imageWidth]="9240"
                    [imageHeight]="6184" [imageScale]="0.32"
                    [imageVersions]="['lrgbhoo', 'lrgb']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'L', brand: '', exposures: [{ count: 156, total: 46800 }] },
                                        { filter: 'R', brand: '', exposures: [{ count: 172, total: 51600 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 195, total: 58500 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 189, total: 56700 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 458, total: 412500 }] },
                                        { filter: 'Oiii', brand: '', exposures: [{ count: 368, total: 330900 }] }]"
                    [nights]="{ count: 44, start: '2023-11-13', end: '2024-01-25' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['Celestron C9.25 SC XLT', 'Celestron RASA 8', 'Deep Sky Instruments (DSI) DSI RC14', 'Sky-Watcher Esprit 150ED', 'Sky-Watcher Quattro 8CF', 'Stellarvue SVX180T', 'TS-Optics 200mm/8 ONTC f/4.5 Newtonian (carbon tube)', 'TS-Optics 250mm/10 ONTC f/4 Newtonian (carbon tube)'],
                                    cameras: ['QHYCCD QHY600PH M', 'ZWO ASI2600MM Pro', 'ZWO ASI533MM Pro', 'ZWO ASI6200MM Pro', 'ZWO ASI1600MM COOL', 'Apogee Alta U16M'] }">
</app-astro-photo>
