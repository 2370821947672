import { Component, OnInit, NgZone, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import OpenSeadragon, { Placement, Point, Rect, Viewer, ControlAnchor } from 'openseadragon';
import { Meta, MetaDefinition } from '@angular/platform-browser';

interface SubExposure {
  count: number;
  exposure: number | null | undefined;
  total: number | null | undefined;
}
interface FilterExposures {
  filter: string;
  brand: string;
  exposures: SubExposure[];
}
interface Equipment {
  mounts: string[],
  telescopes: string[],
  cameras: string[]
}
interface NightsInfo {
  count: number;
  start: string;
  end: string;
}

@Component({
  selector: 'app-astro-photo',
  templateUrl: './astro-photo.component.html',
  styleUrls: ['./astro-photo.component.sass']
})

export class AstroPhotoComponent implements OnInit {


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var zoomInc = 1;
    if (event.key === 'i') {
      this.toggleInfoOverlay(this.osdViewer);
    } else if (event.key === 'Escape') {
      this.hideInfoOverlay(this.osdViewer);
    } else if (event.key === 'm') {
      this.toggleAnnotations();
    } else if (event.key === 'f') {
      this.osdViewer.setFullScreen(!this.osdViewer.isFullScreen());
    // } else if (event.ctrlKey && event.key === '0') {
    //   this.osdViewer.viewport.goHome();
    } else if (/*event.ctrlKey && */event.key === '1') {
      this.osdViewer.viewport.zoomTo(this.osdViewer.viewport.getMaxZoom());
      // event.preventDefault();
    } else if (event.key === 'PageUp') {
      if(this.osdViewer.viewport.getZoom() + zoomInc <= this.osdViewer.viewport.getMaxZoom()) {
        this.osdViewer.viewport.zoomTo(this.osdViewer.viewport.getZoom() + zoomInc);
      } else {
        this.osdViewer.viewport.zoomTo(this.osdViewer.viewport.getMaxZoom());
      }
    } else if (event.key === 'PageDown') {
      if(this.osdViewer.viewport.getZoom() - zoomInc >= this.osdViewer.viewport.getMinZoom()) {
        this.osdViewer.viewport.zoomTo(this.osdViewer.viewport.getZoom() - zoomInc);
      } else {
        this.osdViewer.viewport.zoomTo(this.osdViewer.viewport.getMinZoom());
      }
    }
  }

  @Input() astrophoto: any;
  @Input() imageTilesUrl: string;
  @Input() imageTilesUrls: string[];
  @Input() imageTileSource: string;
  @Input() imageTileSources: string[];
  @Input() annotationsTilesUrl: string;
  @Input() imageFormat: string = 'png';
  @Input() imageWidth: number;
  @Input() imageHeight: number;
  @Input() imageScale: number;
  @Input() imageVersions: string[] = [];
  @Input() filterExposures: FilterExposures[] = [];
  @Input() nights: NightsInfo;
  @Input() equipment: Equipment;
  @Input() watermark: boolean = true;
  @Input() maxZoomLevel?: number | undefined = undefined;


  private DEBUG = false;
  private TILES_DEBUG = false;
  private topCount = 0;
  private infoVisible = true;
  private infoPanelTop = 0;
  private smnallDevice;
  private firstLoad = false;
  private imageVersion = 0;
  private hasRouteParam = false;

  imageTaglines: string[] = [];

  private osdViewer/*: OpenSeadragon.Viewer*/;
  private infoButton: OpenSeadragon.Button;
  private annotationsTile: OpenSeadragon.Options;

  constructor(private _ngZone: NgZone, private router: Router, private activatedRoute: ActivatedRoute, private meta: Meta) {
  }

  currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit() {
    if (this.DEBUG) { console.log('ngOnInit'); }
    let version = this.activatedRoute.snapshot.params.version;
    let info = this.activatedRoute.snapshot.queryParams.info;
    if(info != null) {
      this.infoVisible = info === '1' || info.toLowerCase() === 'true';
    }
    if(version != null) {
      this.hasRouteParam = true;
      let i = this.imageVersions.indexOf(version.toString().toLowerCase());
      if(i >= 0) {
        this.imageVersion = i;
      } else if(/[0-9]/.test(version)) {
        if(version >= this.imageVersions.length) {
          this.imageVersion = this.imageVersions.length - 1;
        } else if (version < 0) {
          this.imageVersion = 0;
        } else {
          this.imageVersion = version;
        }
        this.setVersionInUrl(this.imageVersion, true);
      } else {
        this.imageVersion = 0;
        this.setVersionInUrl(this.imageVersion, true);
      }
      this.imageTaglines.length = 0;
      this.imageTaglines.push.apply(this.imageTaglines, this.getTaglines());
    } else if(this.imageVersions != null && this.imageVersions.length > 0) {
      this.hasRouteParam = true;
      this.setVersionInUrl(0, false);
    }
    // console.log(version);
    // this.activatedRoute.paramMap.subscribe(res => {
    //   version = res.get('version');
    //   console.log('+' + version);
    // });
  }

  ngAfterViewInit() {
    this.loadOpenseaDragon();
  }

  ngAfterViewChecked() {
    if(this.firstLoad) {
      if (this.DEBUG) { console.log('ngAfterViewChecked'); }
      var viewer = this.osdViewer;
      viewer.drawer.element.focus();
      viewer.drawer.element.classList.add("opacity-0");
      setTimeout(function() {
        viewer.viewport.goHome(true);
        viewer.drawer.element.classList.remove("opacity-0");
        viewer.drawer.element.classList.add("fade-in");
        viewer.drawer.element.classList.add("scale-out");
      }, 1250);

      var viewerBG = (this.osdViewer.buttonGroup.element as HTMLElement);
      // var navigator = (this.osdViewer.navigator.element as HTMLElement);

      viewerBG.classList.add("opacity-0");
      $('#info-overlay').addClass("opacity-0");

      if(this.osdViewer.sequenceMode) {
        var paging = (this.osdViewer.paging.element as HTMLElement);
        paging.classList.add("opacity-0");
        setTimeout(function() {
          viewerBG.classList.remove("opacity-0");
          viewerBG.classList.add("scale-in-topleft");
        }, 250);
        var smnallDevice = this.smnallDevice;
        setTimeout(function() {
          paging.classList.remove("opacity-0");
          paging.classList.add(!smnallDevice ? "scale-in-topright" : "scale-in-bottomleft");
        }, 0);
      } else {
        setTimeout(function() {
          viewerBG.classList.remove("opacity-0");
          viewerBG.classList.add("scale-in-topleft");
        }, 250);
      }
      if(this.infoVisible) {
        setTimeout(function() {
          $('#info-overlay').removeClass("opacity-0");
          $('#info-overlay').addClass("scale-out");
        }, 1125);
      } else {
        $('#info-overlay').removeClass("opacity-0"); // opacity is used solely for anîmation, not hiding the overlay itself
      }
    // setTimeout(function() {
      //   navigator.classList.add("flash-bottomleft-once");
      // }, 2500);
      this.firstLoad = false;
    }
  }

  ngOnDestroy() {
    $('#navbar').removeClass('collapse');
    $('footer').removeClass('collapse');
    // $("#navbar").removeClass("navbar-dark"); // TODO cleanup
    // $("#navbar").removeClass("bg-dark");
    // $("#navbar").addClass("navbar-light");
  }


  getTotalExposure(filter?: string): string {
    let totalExposure = 0;

    // Find the filterExposures that match the given filter
    if (filter) {
        const filteredExposures = this.filterExposures.find(fe => fe.filter === filter);
        if (filteredExposures) {
            // Iterate over the exposures and add the total exposure time
            filteredExposures.exposures.forEach(exposure => {
              if(exposure.total) {
                totalExposure += exposure.total;
              } else{
                totalExposure += exposure.count * exposure.exposure;
              }
            });
        }
    }
    else {
        this.filterExposures.forEach(fe => {
            fe.exposures.forEach(exposure => {
              if(exposure.total) {
                totalExposure += exposure.total;
              } else{
                totalExposure += exposure.count * exposure.exposure;
              }
            });
        });
    }

    //convert the total exposure time in human readable format
    let hours = Math.floor(totalExposure / 3600);
    let minutes = Math.floor((totalExposure % 3600) / 60);
    let exposureTime = "";

    if (hours > 0) {
        exposureTime = `${hours}h`;
    }
    if (minutes > 0) {
        exposureTime += `${minutes}m`;
    }

    return exposureTime;
  }

  listExposures(filter?: string): string {
    let exposuresList = "";
    if (filter) {
        const filteredExposures = this.filterExposures.find(fe => fe.filter === filter);
        if (filteredExposures) {
            // exposuresList += filteredExposures.filter + ": ";
            filteredExposures.exposures.forEach(exposure => {
                exposuresList += exposure.count + "x" + (exposure.exposure ? exposure.exposure + "s + " : " + ");
            });
            exposuresList = exposuresList.slice(0, -3);
        } else {
            exposuresList += "No exposures found for filter: " + filter;
        }
    } else {
        this.filterExposures.forEach(fe => {
            exposuresList += fe.filter + ": ";
            fe.exposures.forEach(exposure => {
                exposuresList += exposure.count + "x" + (exposure.exposure ? exposure.exposure + "s + " : " + ");
            });
            exposuresList = exposuresList.slice(0, -3);
            exposuresList += " + ";
        });
        exposuresList = exposuresList.slice(0, -3);
    }
    return exposuresList;
  }

  isImageVersion(imageVersion: number = this.imageVersion) {
    return this.astrophoto.versions != undefined && this.astrophoto.versions.length > 0 && this.imageVersion < this.astrophoto.versions.length;
  }
  getTaglines(imageVersion: number = this.imageVersion) {
    if(this.isImageVersion()) { return this.astrophoto.versions[this.imageVersion].taglines; }
    return this.astrophoto.taglines;
  }

  getImageName() {
    if(this.isImageVersion()) { return this.astrophoto.versions[this.imageVersion].name; }
    return this.astrophoto.name;
  }

  getImageScale() {
    return this.imageWidth + "x" + this.imageHeight + "px (" + this.imageScale + "\"/px)";
  }

  hasFilterList() {
    return this.filterExposures.filter(f => f.brand && f.brand.length > 0).length > 0;
  }

  getFilterList() {
    return this.filterExposures.filter(f => f.brand && f.brand.length > 0).map(f => f.brand).join(', ');
  }

  loadOpenseaDragon() {
    var viewer;

    // $("#navbar").removeClass("navbar-light"); // TODO cleanup
    // $("#navbar").addClass("navbar-dark");
    // $("#navbar").addClass("bg-dark");
    $('#navbar').addClass('collapse');
    $('footer').addClass('collapse');

    OpenSeadragon.setString("Tooltips.Home", "Zoom to fit");
    OpenSeadragon.setString("Tooltips.ZoomOut", "Zoom -");
    OpenSeadragon.setString("Tooltips.ZoomIn", "Zoom +");
    OpenSeadragon.setString("Tooltips.FullPage", "Full-Screen");
    this.smnallDevice = window.innerWidth < 640 || window.innerHeight < 640;
    if (this.DEBUG && this.smnallDevice) { console.log('Small device detected'); }

    var mainImages = [];
    if(this.imageTilesUrls != undefined && this.imageTilesUrls.length > 0) {
      for(let imgTileUrl of this.imageTilesUrls) {
        mainImages.push(
          {
            type: "zoomifytileservice",
            tilesUrl: imgTileUrl,
            width: this.imageWidth,
            height: this.imageHeight,
            fileFormat: this.imageFormat
          });
      }
    } else if(this.imageTileSources != undefined && this.imageTileSources.length > 0) {
      for(let imgTileSource of this.imageTileSources) {
        mainImages.push(
          {
            type: "dzi",
            tileSize: 254,
            tileSource: imgTileSource,
          });
      }
    } else if(this.imageTileSource != undefined) {
      mainImages.push(
        {
          type: "dzi",
          tileSize: 254,
          tileSource: this.imageTileSource,
        });
    } else {
      mainImages.push(
        {
          type: "zoomifytileservice",
          tilesUrl: this.imageTilesUrl,
          width: this.imageWidth,
          height: this.imageHeight,
          fileFormat: this.imageFormat
        });
    }

    var multipleImages = mainImages.length > 1;
    viewer = this._ngZone.runOutsideAngular(() =>
      OpenSeadragon({
        id: "openseadragon1",
        // debugMode: true,
        prefixUrl: "assets/img/openseadragon-fontawesome/",
        // imageSmoothingEnabled: false,
        // visibilityRatio: 0.9,
        // autoResize: true,
        // smoothTileEdgesMinZoom: Infinity,
        // compositeOperation: 'lighter',
        defaultZoomLevel: !this.smnallDevice ? 0 : 0,
        // maxZoomLevel: 1,
        // maxZoomPixelRatio: 1,
        showNavigator: !this.smnallDevice,
        sequenceMode: multipleImages,
        preserveViewport: multipleImages,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tileSources: multipleImages ? mainImages : mainImages[0],
        initialPage: this.imageVersion,
        navigatorPosition: !this.smnallDevice ? 'BOTTOM_LEFT' : undefined,
        sequenceControlAnchor: !this.smnallDevice ? ControlAnchor.TOP_RIGHT : ControlAnchor.BOTTOM_LEFT,
        // Enable touch rotation on tactile devices
        //   gestureSettingsTouch: {
        //   // @ts-ignore
        //   pinchRotate: true
        // }
        maxZoomLevel: this.maxZoomLevel
      })
    );
    this.osdViewer = viewer;
    viewer.subPixelRoundingForTransparency = { '*': 2 }; // FIX for visible tile joins on Firefox and Safari: set to 'NEVER'

    this.annotationsTile = <OpenSeadragon.Options>{
      type: "zoomifytileservice",
      index: 1,
      tilesUrl: this.annotationsTilesUrl,
      width: this.imageWidth,
      height: this.imageHeight,
      fileFormat: this.imageFormat,
      opacity: 0
    };

    var homeButton = new OpenSeadragon.Button({
      tooltip: 'Back to gallery',
      srcRest: 'assets/img/openseadragon-fontawesome/house.png',
      srcGroup: 'assets/img/openseadragon-fontawesome/house.png',
      srcHover: 'assets/img/openseadragon-fontawesome/house_pressed.png',
      srcDown: 'assets/img/openseadragon-fontawesome/house_pressed.png'
    });
    var toggleAnnotationsButton = new OpenSeadragon.Button({
      tooltip: 'Toggle Annotations',
      srcRest: 'assets/img/openseadragon-fontawesome/map-marked-alt.png',
      srcGroup: 'assets/img/openseadragon-fontawesome/map-marked-alt.png',
      srcHover: 'assets/img/openseadragon-fontawesome/map-marked-alt_hover.png',
      srcDown: 'assets/img/openseadragon-fontawesome/map-marked-alt_hover.png'
    });
    var zoomOneToOneButton = new OpenSeadragon.Button({
      tooltip: 'Zoom 1:1',
      srcRest: 'assets/img/openseadragon-fontawesome/search-location.png',
      srcGroup: 'assets/img/openseadragon-fontawesome/search-location.png',
      srcHover: 'assets/img/openseadragon-fontawesome/search-location_hover.png',
      srcDown: 'assets/img/openseadragon-fontawesome/search-location_hover.png'
    });
    this.infoButton = new OpenSeadragon.Button({
      tooltip: 'Information',
      srcRest: 'assets/img/openseadragon-fontawesome/info_rest.png',
      srcGroup: 'assets/img/openseadragon-fontawesome/info_rest.png',
      srcHover: 'assets/img/openseadragon-fontawesome/info_hover.png',
      srcDown: 'assets/img/openseadragon-fontawesome/info_hover.png'
    });

    if(viewer.paging != undefined) {
      var isFirstVersion = this.imageVersion == 0;
      var isLastVersion = this.imageVersion == mainImages.length - 1;
      var margin = 'margin: ' + (!this.smnallDevice ? '10px 20px 0px 0px' : '0px 0px 10px 20px') + '; ';
      var opacity = (!this.smnallDevice ? (isLastVersion ? ' opacity: 0.2;' : '') : (isFirstVersion ? ' opacity: 0.2;' : '')) + ';';
      var index = !this.smnallDevice ? viewer.paging.buttons.length-1 : 0;
      viewer.paging.buttons[index].element.setAttribute("style",
        "background: none transparent; border: none; " + margin + "padding: 0px; position: relative; touch-action: none; display: inline-block; pointer-events: auto;" + opacity);
    }

    viewer.buttonGroup.buttons.unshift(homeButton);
    viewer.buttonGroup.element.insertBefore(homeButton.element, viewer.buttonGroup.element.firstChild);
    homeButton.element.addEventListener('click', (event) => this.homeButton_Click(event, this.hasRouteParam));
    homeButton.element.setAttribute("style", "background: none transparent; border: none; margin-left: 10px; padding: 0px; position: relative; touch-action: none; display: inline-block; pointer-events: auto;");

    viewer.buttonGroup.buttons.push(zoomOneToOneButton);
    viewer.buttonGroup.element.appendChild(zoomOneToOneButton.element);
    zoomOneToOneButton.element.addEventListener('click', (event) => this.zoomOneToOneButton_Click(event, viewer));

    viewer.buttonGroup.buttons.push(toggleAnnotationsButton);
    viewer.buttonGroup.element.appendChild(toggleAnnotationsButton.element);
    toggleAnnotationsButton.element.addEventListener('click', (event) => this.toggleAnnotationsButton_Click(event, viewer));

    viewer.buttonGroup.buttons.push(this.infoButton);
    viewer.buttonGroup.element.appendChild(this.infoButton.element);
    this.infoButton.element.addEventListener('click', (event) => this.infoButton_Click(event, viewer));
    (this.infoButton.element as HTMLElement).classList.add("rotate-360-repeat");

    if(!this.smnallDevice) {
      new OpenSeadragon.MouseTracker({
        element: 'info-overlay',
        clickHandler: (e) => this.textOverlay_Click(e, viewer),
        // dragHandler: (e) => this.textOverlay_Drag(e)
      });
    } else {
      new OpenSeadragon.MouseTracker({
        element: 'info-overlay',
        clickHandler: (e) => this.textOverlay_Click(e, viewer),
        dragHandler: (e) => this.textOverlay_Drag(e)
      });
    }

    var countEl = document.querySelector('#tile-loading-progress');
    viewer.addHandler('update-viewport', () => this.updateProgress(viewer, countEl));

    viewer.addHandler('open', (event) => this.viewer_Open(event, viewer));
    viewer.addHandler('pan', (event) => this.viewport_Pan(event, viewer));
    viewer.addHandler('animation-finish', (event) => this.viewport_AnimationFinish(event, viewer));
    viewer.addHandler('update-overlay', (event) => this.overlay_Updated(event, viewer));
    viewer.addHandler('update-viewport', (event) => this.viewport_Updated(event, viewer));
    viewer.addHandler('tile-unloaded', (event) => function () { console.log('tile-unloaded'); this.topCount--; });
    viewer.addHandler('page', (event) => this.viewer_Page(event, this.hasRouteParam));
    viewer.addHandler('resize', (event) => this.viewer_Resize(viewer));
    viewer.addHandler('zoom', (event) => this.viewport_Zoom(event, viewer));
    viewer.addHandler('animation', (event) => this.viewport_Animation(event, viewer));

    if (this.DEBUG) {
      let debugEvents = ['add-item', 'remove-item', 'add-overlay', 'remove-overlay', 'clear-overlay', 'update-overlay',
                        'animation-start', 'animation', 'tile-load-failed', 'open-failed', 'reset-size', 'after-resize',
                        'update-viewport', 'update-level', 'constrain', 'canvas-key', 'update-level', 'viewport-change',
                        'canvas-pinch', 'canvas-release'/*, 'update-tile'*/];
      function debugEvent(eventName, event: any) {
        // let top = parseInt((document.querySelector('#info-overlay') as HTMLElement).style.top, 10);
        // console.log('top: ' + top);
        console.log(eventName, event/*, 'top: ' + top*/);
      }
      for (let debugEventName of debugEvents) {
        viewer.addHandler(debugEventName, (event) => debugEvent(debugEventName, event));
      }
      // viewer.addHandler('animation-start', (event) => console.log('animation-start'));
      // // viewer.addHandler('animation', (event) => console.log('animation'));
      // viewer.addHandler('add-overlay', (event) => console.log('add-overlay'));
      // // viewer.addHandler('zoom', (event) => console.log('zoom'));
      // viewer.addHandler('tile-load-failed', (event) => console.log('tile-load-failed'));
      // viewer.addHandler('open-failed', (event) => console.log('open-failed'));
      // viewer.addHandler('reset-size', (event) => console.log('reset-size'));
      // viewer.addHandler('after-resize', (event) => console.log('after-resize'));
      // // viewer.addHandler('update-viewport', (event) => console.log('update-viewport'));
      // // viewer.addHandler('update-level', (event) => console.log('update-level'));
      // viewer.addHandler('constrain', (event) => console.log('constrain'));
      // viewer.addHandler('constrain', (event) => console.log('constrain'));
      // viewer.addHandler('canvas-key', (eventSource, event) => console.log('canvas-key: ', eventSource));
      // viewer.addHandler('update-level', (event) => console.log('update-level'));
      // // viewer.addHandler('update-viewport', (event) => console.log('update-viewport'));
      // viewer.addHandler('viewport-change', (event) => console.log('viewport-change'));
      // viewer.addHandler('canvas-pinch', (event) => console.log('canvas-pinch'));
      // viewer.addHandler('canvas-release', (event) => console.log('canvas-release'));
      // viewer.addHandler('update-tile', (event) => console.log('update-tile'));
    }

    this.firstLoad = true;
  }

  private viewer_Resize(viewer: any) {
    if (this.DEBUG) { console.log('viewer_Resize'); }
    let small = this.smnallDevice;
    this.smnallDevice = window.innerWidth < 640 || window.innerHeight < 640;
    if(small != this.smnallDevice) {
      this.updateInfoOverlay(viewer);
    }
  }
  private overlay_Updated(event: any, viewer: any) {
    var infoOverlayElement = document.querySelector('#info-overlay');
    if (this.DEBUG) { '#' + console.log((event.element as HTMLElement).id + ' updated'); }
    if (event.element === infoOverlayElement) {
      var infoOverlay = viewer.getOverlayById(infoOverlayElement);
      var infoOverlayBounds = infoOverlay.getBounds(viewer.viewport);
      var viewportBounds = viewer.viewport.getBounds(true);

      if (this.DEBUG) {
        console.log('viewport: ' + viewportBounds);
        console.log('overlay: ' + infoOverlayBounds);
      }

      // if (viewportBounds.height < 10 && infoOverlayBounds.height < 10 && viewportBounds.height < infoOverlayBounds.height && viewportBounds.y > infoOverlayBounds.y) {
      //   var diff = infoOverlayBounds.height - viewportBounds.height;
      //   var newPoint = new Point(infoOverlayBounds.x, infoOverlayBounds.y + diff);
      //   if (this.DEBUG) { console.log('new: ' + newPoint); }
      //   viewer.updateOverlay(infoOverlayElement, newPoint, Placement.TOP_LEFT);
      // }
    }
  }

  private viewport_Updated(event: any, viewer: any) {
    if (this.DEBUG) { console.log('viewport-Updated'); }
    if(this.smnallDevice) {
      setTimeout(() => {
        (document.querySelector('#info-overlay') as HTMLElement).style.top = this.infoPanelTop + "px";
        // let top = parseInt((document.querySelector('#info-overlay') as HTMLElement).style.top, 10);
        // console.log('top: ' + top);
      }, 10);
      // this.updateInfoOverlay(viewer);
      // (document.querySelector('#info-overlay') as HTMLElement).style.top = this.infoPanelTop + "px";
    }
  }

  private setVersionInUrl(version: number, hasRouteParam: boolean) {
    var baseUrl = window.location.href;
    if(hasRouteParam) {
      baseUrl = window.location.href.match(/^(.*)\/.*/)[1];
    } else {
      baseUrl = window.location.href.match(/^.*/)[0];
    }
    var newUrl = baseUrl + '/' + this.imageVersions[version];
    // console.log(newUrl);
    window.history.pushState({}, '', newUrl);
  }

  private viewer_Page(event: any, hasRouteParam: boolean) {
    if(this.DEBUG) {console.log('page: ' + event.page); }
    if(event.page >= 0) {
      this.setVersionInUrl(event.page, hasRouteParam);
      this.imageVersion = event.page;
      this.imageTaglines.length = 0;
      this.imageTaglines.push.apply(this.getTaglines(event.page));
    }
  }

  private viewer_Open(event: any, viewer: any) {
    if (this.DEBUG) { console.log('viewer_Open'); }
    var viewportBounds = viewer.viewport.getBounds(true);
    if (this.DEBUG) {
      // var imageBounds = viewer.viewport.viewportToImageCoordinates(viewportBounds.x, viewportBounds.y);
      console.log('viewport: ' + viewportBounds);
      // console.log('image: ' + imageBounds);
    }
    viewer.addTiledImage({
      tileSource: this.annotationsTile,
      index: 1,
      opacity: this.annotationsTile.opacity
      // ,replace: true
    });

    var infoOverlayElement = document.querySelector('#info-overlay');
    // infoOverlayElement.setAttribute("style", "opacity: 0");
    // viewer.addOverlay(document.querySelector('#info-overlay'), new Point(0.4925, 0.32125), Placement.CENTER);
    // viewer.addOverlay(document.querySelector('#info-overlay'), new Rect(0, 0, 1, 1)); //, Placement.CENTER);
    viewer.addOverlay({
      // element: document.querySelector('#info-overlay'),
      element: infoOverlayElement,
      location: !this.smnallDevice ? new Rect(0.2, 0.1, 0.6, 0.8) : viewportBounds
    });
    viewer.addOverlay(document.querySelector('#tile-loading-progress'));
    viewer.addOverlay(document.querySelector('#copyright'));

    this.updateInfoOverlay(viewer);

    $('.header-close-button').on('click', (event) => this.hideInfoOverlay(viewer));
  }

  private scaleInfoOverlay(viewer: any) {
    if (this.infoVisible) {
      var zoom = viewer.viewport.getZoom(true);
      // var imageZoom = viewer.viewport.viewportToImageZoom(zoom);
      var infoOverlayContentElement = document.querySelector('#info-overlay-content');

      if (!this.smnallDevice) {
        let wdith = 100 * 1 / zoom;
        infoOverlayContentElement.setAttribute("style", "transform: scale(" + 1 * zoom + "); transform-origin: 0% 0% 0px; width: " + wdith + "%;");
      // } else {
      //   let wdith = 100 * 1 / imageZoom;
      //   infoOverlayContentElement.setAttribute("style", "transform: scale(" + 1 * imageZoom + "); transform-origin: 0% 0% 0px; width: " + wdith + "%;");
      } else {
        infoOverlayContentElement.setAttribute("style", "");
      }
    }
  }
  private viewport_Pan(event: any, viewer: any) {
    if (this.DEBUG) { console.log('pan'); }
    // if (this.infoVisible) {
    //   viewer.updateOverlay(document.querySelector('#info-overlay'), event.center, Placement.CENTER);
    // }
  }

  private viewport_Zoom(event: any, viewer: any) {
    var zoom = viewer.viewport.getZoom(true);
    // var imageZoom = viewer.viewport.viewportToImageZoom(zoom);
    if (this.DEBUG) { console.log('zoom: ' + zoom); } // + ' - image zoom: ' + imageZoom); }
    // let top = parseInt((document.querySelector('#info-overlay') as HTMLElement).style.top, 10);
    // console.log('top: ' + top);

    if (this.DEBUG) {
      var viewportBounds = viewer.viewport.getBounds(true);
      // var imageBounds = viewer.viewport.viewportToImageCoordinates(viewportBounds.x, viewportBounds.y);
      console.log('viewport: ' + viewportBounds);
      // console.log('image: ' + imageBounds);
    }
    this.scaleInfoOverlay(viewer);
    // if (this.infoVisible) {
    //   // var infoOverlayElement = document.querySelector('#info-overlay');
    //   var infoOverlayContentElement = document.querySelector('#info-overlay-content');
    //   // add transform scale
    //   // infoOverlayElement.setAttribute("style", "transform: scale(" + 2 * viewer.viewport.getZoom(true) + ")");

    //   let wdith = 100 * 1 / viewer.viewport.getZoom(true);
    //   infoOverlayContentElement.setAttribute("style", "transform: scale(" + 1 * viewer.viewport.getZoom(true) + "); transform-origin: 0% 0% 0px; width: " + wdith + "%;");

    //   // viewer.updateOverlay(document.querySelector('#info-overlay'), viewer.viewport.getCenter(true), Placement.CENTER);
    //   // viewer.updateOverlay(infoOverlayElement, new Rect(0, 0, 1, 1));
    // }
  }

  private viewport_Animation(event: any, viewer: any) {
    // if (this.DEBUG) { console.log('animation'); }
    this.scaleInfoOverlay(viewer);
    if(this.smnallDevice) {
      // let top = parseInt((document.querySelector('#info-overlay') as HTMLElement).style.top, 10);
      // console.log('top: ' + top);
      this.updateInfoOverlay(viewer);
      (document.querySelector('#info-overlay') as HTMLElement).style.top = this.infoPanelTop + "px";
    }
  }

  private viewport_AnimationFinish(event: any, viewer: any) {
    if (this.DEBUG) { console.log('animation-finish'); }
    this.scaleInfoOverlay(viewer);
    if(this.smnallDevice) {
      this.updateInfoOverlay(viewer);
      (document.querySelector('#info-overlay') as HTMLElement).style.top = this.infoPanelTop + "px";
    }
    // if (this.infoVisible) {
    //   var infoOverlayContentElement = document.querySelector('#info-overlay-content');
    //   let wdith = 100 * 1 / viewer.viewport.getZoom(true);
    //   infoOverlayContentElement.setAttribute("style", "transform: scale(" + 1 * viewer.viewport.getZoom(true) + "); transform-origin: 0% 0% 0px; width: " + wdith + "%;");
    //   var infoOverlayElement = document.querySelector('#info-overlay');
    //   // viewer.updateOverlay(infoOverlayElement, viewer.viewport.getCenter(true), Placement.CENTER);
    //   // viewer.updateOverlay(infoOverlayElement, new Rect(0, 0, 1, 1));
    // }
  }

  private textOverlay_Click(event: any, viewer: any) {
    if (this.DEBUG) { console.log('textOverlay_Click'); }
    var target = $(event.originalTarget);
    if (target.is('a') || target.parents().is('a')) {
      var link = target.is('a') ? target : target.parents('a');
      var linkUrl = link.attr('href');
      if(linkUrl.startsWith('/')) {
        linkUrl = this.getBaseUrl(this.hasRouteParam) + linkUrl;
      }
      if (link.attr('target') === '_blank') {
        window.open(linkUrl);
      } else {
        location.href = linkUrl;
      }
    }
    // if (target.is('a')) {
    //   if (target.attr('target') === '_blank') {
    //     window.open(target.attr('href'));
    //   } else {
    //     location.href = target.attr('href');
    //   }
    // }
    else if(target.hasClass('header-close-button') || (target.parent() != null && target.parent().hasClass('header-close-button'))) {
      this.hideInfoOverlay(viewer);
    } else {
      var button = (this.infoButton.element as HTMLElement);
      (this.infoButton.element as HTMLElement).classList.remove("rotate-360-repeat");
      setTimeout(function() {
          button.classList.add("rotate-360-repeat");
        }, 10);
    }
  }

  private textOverlay_Drag(event: any) {
    if(this.smnallDevice) {
      var target = $(event.originalTarget);
      var delta = event.delta;
      var infoOverlay = document.querySelector('#info-overlay');
      var infoOverlayContentElement = document.querySelector('#info-overlay-content');
      var topVal = parseInt((infoOverlay as HTMLElement).style.top, 10);
      var height = parseInt((infoOverlay as HTMLElement).style.height, 10);
      var contentHeight = (infoOverlayContentElement as HTMLElement).offsetHeight;
      var minTop = -(contentHeight - height - 10);
      // console.log('top: ' + topVal + ' - height: ' + height + ' - contentHeight: ' + contentHeight + ' - mh: ' + (contentHeight - height));
      var top = topVal + delta.y;
      if (top > 0) {
        top = 0;
      } else if (top < minTop) {
        top = minTop;
      }
      (infoOverlay as HTMLElement).style.top = top + 'px';
      // this.infoPanelTop = top;

      var button = (this.infoButton.element as HTMLElement);
      (this.infoButton.element as HTMLElement).classList.remove("rotate-360-repeat");
      setTimeout(function() {
          button.classList.add("rotate-360-repeat");
        }, 10);
    }
}

  private textOverlay_Scroll(event: any) {
    var target = $(event.originalTarget);
    var delta = event.scroll;
    var infoOverlay = document.querySelector('#info-overlay');
    var topVal = parseInt((infoOverlay as HTMLElement).style.top, 10);
    (infoOverlay as HTMLElement).style.top = (topVal + delta) + 'px';
  }

  private languageButton_Click(event: any, language: string) {
    window.location.href = "/" + language + this.router.url;
  }

  private infoButton_Click(event: any, viewer: any) {
    if (this.DEBUG) { console.log(this.infoVisible ? 'hidding info overlay': 'showing info overlay'); }
    this.toggleInfoOverlay(viewer);
  }

  private toggleInfoOverlay(viewer: any) {
    this.infoVisible = !this.infoVisible;
    this.updateInfoOverlay(viewer);
  }

  private showInfoOverlay(viewer: any) {
    if (this.DEBUG) { console.log('showing info overlay'); }
    this.infoVisible = true;
    this.updateInfoOverlay(viewer);
  }

  private hideInfoOverlay(viewer: any) {
    if (this.DEBUG) { console.log('hiding info overlay'); }
    this.infoVisible = false;
    this.infoPanelTop = 0;
    this.updateInfoOverlay(viewer);
  }

  private updateInfoOverlay(viewer: any) {
    if (this.DEBUG) { console.log('updating info overlay'); }
    if (this.infoVisible) {
      this.imageTaglines.length = 0;
      this.imageTaglines.push.apply(this.imageTaglines, this.getTaglines());
      let viewportBounds = viewer.viewport.getBounds(true);
      // console.log('viewport: ' + viewportBounds);
      viewer.updateOverlay(document.querySelector('#info-overlay'), !this.smnallDevice ? new Rect(0.2, 0.1, 0.6, 0.8) : viewportBounds);
      // viewer.updateOverlay(document.querySelector('#info-overlay'), new Rect(0, 0, 1, 1));
      // viewer.updateOverlay(document.querySelector('#info-overlay'), viewer.viewport.getCenter(true), Placement.CENTER);
      (this.infoButton.element as HTMLElement).classList.add("rotate-360-repeat");
    } else {
      viewer.updateOverlay(document.querySelector('#info-overlay'), new Point(10, 10));
      (this.infoButton.element as HTMLElement).classList.remove("rotate-360-repeat");
    }
  }

  private toggleAnnotationsButton_Click(event: any, viewer: any) {
    this.toggleAnnotations();
  }

  private toggleAnnotations() {
    if (this.annotationsTile.opacity === 1)
      this.annotationsTile.opacity = 0;
    else
    this.annotationsTile.opacity = 1;
    this.osdViewer.addTiledImage({
      tileSource: this.annotationsTile,
      index: 1,
      opacity: this.annotationsTile.opacity,
      replace: true
    });
  }

  private zoomOneToOneButton_Click(event: any, viewer: any) {
    viewer.viewport.zoomTo(viewer.viewport.getMaxZoom());
  }

  private getBaseUrl(hasRouteParam: any) {
    var baseUrl = window.location.href;
    if(hasRouteParam) {
      baseUrl = window.location.href.match(/^(.*\/).*\//)[1];
    } else {
      baseUrl = window.location.href.match(/^.*\//)[0];
    }
    return baseUrl.toString();
  }

  private homeButton_Click(event: any, hasRouteParam: any) {
    window.location.href = this.getBaseUrl(hasRouteParam) + 'gallery';
  }

  private updateProgress(viewer: any, countEl: any) {
    if (this.DEBUG) { console.log('updateProgress'); }
    // We need to check every frame
    // NOTE: You can remove this handler after you're done with the progress bar, to keep things efficient
    var tilesToLoadCount = 0;

    // There may be more than one image in the scene, so we go through them all
    var count = viewer.world.getItemCount(); // image counted even if not currently displayed
    var tiledImage, i, levelKey, level, xKey, x, yKey, y, needCount, tileCount;
    for (i = 0; i < count; i++) {
      tiledImage = viewer.world.getItemAt(i);

      var coverage = tiledImage.loadingCoverage;// coverage;
      if (!coverage || tiledImage.getFullyLoaded() /*|| !tiledImage.needsDraw()*/) {
        continue;
      }

      for (levelKey in coverage) {
        level = coverage[levelKey];
        needCount = 0;
        tileCount = 0;

        for (xKey in level) {
          x = level[xKey];

          for (yKey in x) {
            y = x[yKey];

            tileCount++;
            if (!y) {
              needCount++;
            }
          }
        }

        if (tileCount > 1) {
          // Levels that only have a single tile often aren't loaded at all, so we don't count them
          tilesToLoadCount += needCount;
        }
      }
    }

    this.topCount = Math.max(this.topCount, tilesToLoadCount);
    if (this.topCount && tilesToLoadCount > 0 /*&& this.topCount > tilesToLoadCount*/) { // this.topCount != tilesToLoadCount not ideal but detects fully-loaded
      var percent = 100 * (this.topCount - tilesToLoadCount) / this.topCount;
      countEl.innerHTML = Math.round(percent) + ' %';
      if (this.TILES_DEBUG) { console.log('tiles: ' + tilesToLoadCount + '/' + this.topCount); }
    } else {
      this.topCount = 0;
      tilesToLoadCount = 0;
      countEl.innerHTML = '';
    }
  }
}
